export function isValidUrl(url: string) {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}



export function isLocalImage(url: string) {
  if (!url) return false

  return url.includes("/app/assets") && !url.includes("https")
}